import React, { useState, useEffect } from "react";
import axios from 'axios';
import Line from "../components/Line";
import WeightManagement from "../components/WeightManagement";
import FrameEnhancedDigestion from "../components/FrameEnhancedDigestion";
import JoinOurSustainability from "../components/JoinOurSustainability";
import FrameComponent from "../components/FrameComponent";
import styles from "./Iteration.module.css";
import FrameComponent2 from "../components/FrameComponent2";
import DropdownArrowUp from "../components/DropdownArrowUp";
import MultiImageText from "../components/MultiImageText";

const Iteration = (props) => {
  const [product, setProduct] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);
  const [journeyData, setJourneyData] = useState([]);

  var data = JSON.parse(localStorage.getItem("ProductData"));
  var aic_data = JSON.parse(localStorage.getItem("ProductdatafromAIC"));
  var ProductDetails = localStorage.getItem("ProductDetails");
  var ProductID = localStorage.getItem("ProductID");

  const discountedPrice = Number(data.additionalDiscount);
  const originalcost = Number(data.price) - Number(data.discountmrp);
  const totalcost = Number(data.price) + Number(data.discountmrp);

  const skuid = data.productId;
  console.log(skuid);

  useEffect(() => {
    const fetchJourneyData = async () => {
      try {
        const response = await axios.get(`https://1c6see68bj.execute-api.ap-south-1.amazonaws.com/prod/product-journey?skuid=${skuid}`);
        setJourneyData(response.data.ProductJourney);
      } catch (error) {
        console.error('Error fetching journey data:', error);
      }
    };

    fetchJourneyData();
  }, [skuid]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const aboutProductWords = data.description.split(" ");
  const isLongText = aboutProductWords.length > 30;
  let displayText;
  if (isLongText && !isExpanded) {
    displayText = `${aboutProductWords.slice(0, 30).join(" ")}... `;
  } else {
    displayText = data.description;
  }

  const productjour = journeyData.length > 0 ? journeyData.map(item => ({
    time: "June 5, 2024",
    title: item.title,
    description: item.description
  })) : [];

  var productn = data.title;

  const uidRegex = /TESTQR/;

  // Filter out invalid video links
  const validVideos = data.videoLinks.filter(video => video.value && video.value.trim() !== "");

  // Check for valid key features
  const hasValidKeyFeatures = data.keyfeature && data.keyfeature.length >= 1 && data.keyfeature[0] !== "";

  // Check for valid directions to use
  const hasValidDirectionsToUse = data.diresctiontouse && data.diresctiontouse.length > 1 && data.diresctiontouse[0] !== "";

  // Check for valid sustainability
  const hasValidSustainability = data.sustainability && data.sustainability.length > 1 && data.sustainability[0] !== "";

  // Check for valid nutrition items
  const hasValidNutritionItems = data.nutritionItems && data.nutritionItems.length > 1 && data.nutritionItems[0].name !== "" && data.nutritionItems[0].value !== "";

  return (
    <div
      className={styles.iteration2}
      style={{ backgroundColor: data.bgcolor }}
    >
      <section
        className={styles.keyBenefits}
        style={{ backgroundColor: data.bgcolor }}
      >
        {uidRegex.test(ProductID) ? (
          <div
            style={{
              colors: "white",
              backgroundColor: "black",
              width: "100%",
              height: "40px",
              display: "flex",
              alignContent: "centers",
              justifyContent: "center",
            }}
          >
            <b>{` `}</b>
            <div style={{ margin: "10px" }}>Test Product View</div>
          </div>
        ) : null}
        {ProductDetails !== "unbreak" ? (
          <Line
            aic_data={aic_data}
            skuid={skuid}
            ProductID={ProductID}
            data={data}
            ProductDetails={ProductDetails}
            productn={productn}
            images={data.photo}
            product={productn}
          />
        ) : (
          <DropdownArrowUp
            productn={productn}
            ProductID={ProductID}
            images={data.photo}
            data={data}
            ProductDetails={ProductDetails}
            skuid={skuid}
            product={productn}
          />
        )}
        <div className={styles.dropdownTop}>
          <b
            className={styles.kandavikaCowGhee}
            style={{ color: data.headingcolor }}
          >
            {productn}
          </b>
          <b
            className={styles.kandavikaCowGhee}
            style={{ color: data.headingcolor, fontSize: '12px' }}
          >
            {data && data.tagline !== "" && (
              data.tagline
            )}
          </b>
          <div className={styles.discoverTheGoodnessOfKandaParent}>
            <div
              className={styles.discoverTheGoodnessContainer}
              style={{ color: data.textcolor }}
            >
              <span>{displayText}</span>
              {isLongText && !isExpanded && (
                <span className={styles.more} onClick={toggleExpand}>
                  more
                </span>
              )}
            </div>
          </div>
        </div>
      </section>

      <WeightManagement data={data} productJourneyData={productjour} />

      {hasValidDirectionsToUse && (
        <section className={styles.keyBenefitsParent}>
          <b className={styles.keyBenefits2} style={{ color: data.headingcolor }}>
            Directions to Use
          </b>
          <div className={styles.frameEnhancedDigestionParent}>
            <MultiImageText data={data.diresctiontouse} title="Directions to Use" datac={data} />
          </div>
        </section>
      )}

      {hasValidSustainability && (
        <section className={styles.keyBenefitsParent}>
          <b className={styles.keyBenefits2} style={{ color: data.headingcolor }}>
            Sustainability
          </b>
          <div className={styles.frameEnhancedDigestionParent}>
            <MultiImageText data={data.sustainability} title="Sustainability" datac={data} />
          </div>
        </section>
      )}

      {data.packagetext && data.packagetext.length > 5 && (
        <section className={styles.keyBenefitsParent}>
          <b className={styles.keyBenefits2} style={{ color: data.headingcolor }}>
          What is in the package?
          </b>
          <div className={styles.frameEnhancedDigestionParent} style={{ color: data.textcolor, marginBottom: '12px' }}>
            <span>{data.packagetext} </span>
          </div>
        </section>
      )}

      {hasValidNutritionItems && (
        <section className={styles.keyBenefitsParent}>
          <b className={styles.keyBenefits2} style={{ color: data.headingcolor }}>
          Specification
          </b>
          <div className={styles.frameEnhancedDigestionParent} style={{ color: data.textcolor, marginBottom: '12px' }}>
            {data.nutritionItems.map((item, index) => (
              <div key={index} className={styles.nutritionItem} style={{ marginBottom: '1px', gap: '7px' }}>
                <span className={styles.bulletPoint}>• &nbsp;</span>
                <span>{item.name} -</span>
                <span className={styles.nutritionValue}>  {item.value} ({item.unit})</span>
              </div>
            ))}
          </div>
        </section>
      )}

      {hasValidKeyFeatures && (
        <section className={styles.keyBenefitsParent}>
          <b className={styles.keyBenefits2} style={{ color: data.headingcolor }}>
            Key Benefits
          </b>
          <div className={styles.frameEnhancedDigestionParent}>
            <FrameEnhancedDigestion keyBenefits={data.keyfeature} data={data} />
          </div>
        </section>
      )}

      {data && data.brandDetail !== "" && (
        <section className={styles.logoContainer}>
          <b className={styles.ourStory} style={{ color: data.headingcolor }}>
            Our Story
          </b>
          <div className={styles.kandavikaStandsAt} style={{ color: data.textcolor }}>
            {data.brandDetail}
          </div>
        </section>
      )}

      {data && data.mission !== "" && (
        <section className={styles.logoContainer}>
          <b className={styles.ourStory} style={{ color: data.headingcolor }}>
            Our Mission
          </b>
          <div className={styles.kandavikaStandsAt} style={{ color: data.textcolor }}>
            {data.mission}
          </div>
        </section>
      )}

      {validVideos.length > 0 && (
        <JoinOurSustainability data={data} videos={validVideos} />
      )}

      <b className={styles.productJourney} style={{ color: data.headingcolor }}>
        Product Journey
      </b>
      <section
        className={styles.joinOurSustainabilityDriveParent}
        style={{ color: data.textcolor }}
      ></section>
      <img
        className={styles.cameraCutoutIcon}
        alt=""
        src="/camera-cutout.svg"
      />
      {data.condition === "break" ? <FrameComponent /> : null}
      <FrameComponent2 socialMediaLinks={data.socialMediaLinks} />
    </div>
  );
};

export default Iteration;
