import { useEffect, useState } from "react";
import styles from "./Line.module.css";

const Line = ({ images, product, data, ProductDetails, ProductID }) => {
  const [index, setIndex] = useState(0);
  const [startX, setStartX] = useState(0);
  const [newDateStr, setNewDateStr] = useState("");
  const [serialNumbers,setserialNumbers]=useState(1)

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prev) => (prev + 1) % images.length);
    }, 6000); // Adjust the interval for automatic sliding
    return () => clearInterval(interval);
  }, [index, images.length]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const uid = params.get("uid");
console.log(uid)
    // Use regular expression to match the text between 'x's
    const match = uid.match(/x(.*?)x/);
// Regular expression to match 'serial=' followed by the number
const regex = /serial=([^\s]+)/;

// Execute the regular expression on the text
const match2 = uid.match(regex);
const serialNumber = match2 ? match2[1] : null;

console.log(serialNumber);
setserialNumbers(serialNumber)
    if (match && match[1]) {
      const extractedText = match[1]; // "2024-08-28-5"

      // Split the extracted text into date and days to add
      const parts = extractedText.split("-");
      const baseDateStr = parts.slice(0, 3).join("-"); // "2024-08-28"
      const daysToAdd = parseInt(parts[3], 10); // 5

      // Convert base date string to a Date object
      const baseDate = new Date(baseDateStr);

      // Add the days to the base date
      baseDate.setDate(baseDate.getDate() + daysToAdd);

      // Format the resulting date as YYYY-MM-DD
      const year = baseDate.getFullYear();
      const month = String(baseDate.getMonth() + 1).padStart(2, '0');
      const day = String(baseDate.getDate()).padStart(2, '0');

      const calculatedDate = `${year}-${month}-${day}`;
      setNewDateStr(calculatedDate);
    }
  }, []);

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    const delta = e.changedTouches[0].clientX - startX;
    handleSwipe(delta);
  };

  const handleSwipe = (delta) => {
    if (delta > 50) {
      setIndex((prev) => (prev - 1 + images.length) % images.length);
    } else if (delta < -50) {
      setIndex((prev) => (prev + 1) % images.length);
    }
  };

  // Check if the product starts with "eggoz" (case-insensitive)
const showProductSection = product.toLowerCase().startsWith("eggoz") || product.toLowerCase().startsWith("generic")|| product.toLowerCase().startsWith("test");

  return (
    <section className={styles.dropdownArrowUp} style={{ backgroundColor: data.bgcolor }}>
      <div className={styles.navigationBar} style={{ backgroundColor: data.bgcolor }}>
        <div
          style={{ display: "flex", justifyContent: "center", backgroundColor: data.bgcolor }}
          className={styles.ingredientsFrame}
        >
          <div className={styles.newArrivalsFrame} style={{ backgroundColor: data.bgcolor }}>
            <img
              className={styles.image2Icon}
              loading="eager"
              alt=""
              src={data.brandlink || "/arvologo1.png"}
            />
          </div>
        </div>
        <br></br>
        {showProductSection && (
          <div className={styles.vector}>
            <div className={styles.sealUnseal} style={{ backgroundColor: '#C7EEB0' }}>
              <div className={styles.phsealCheckParent}>
                <img
                  className={styles.phsealCheckIcon}
                  loading="eager"
                  alt=""
                  src="/phsealcheck.svg"
                />
                <div className={styles.authLabel}>
                  <div>
                    <span>{`Your Product `}</span>
                    <span className={styles.kandavikaCowGhee}>
                      <span className={styles.authemticProductName}>{product}</span>
                    </span>
                    {ProductDetails === 'unbreak' ? (
                      <span>
                        <b className={styles.b}>{` `}</b>
                        <span>is authentic and sealed.</span>
                      </span>
                    ) : (
                      <span>
                        <b className={styles.b}>{` `}</b>
                        <span>is authentic and the product will expire on {newDateStr}.</span>
                      </span>
                    )}
                  </div>
                  <div>
                   
                      <>
                        <span className={styles.authemticProductName}>Seal Number:</span>
                        <span className={styles.span}> {ProductID}-{serialNumbers}</span>
                      </>
                  
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                overflow: 'hidden',
                width: '100%',
                position: 'relative',
                objectFit: 'cover'
              }}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
            >
              <div
                style={{
                  display: 'flex',
                  transform: `translate3d(${-index * 100}%, 0, 0)`,
                  transition: 'transform 0.5s ease',
                }}
              >
                {images.map((image, i) => (
                  <div key={i} style={{ flex: '0 0 100%', boxSizing: 'border-box' }}>
                    <img
                      src={image}
                      alt={`Slide ${i + 1}`}
                      className={styles.joinOurDriveText}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        overflow: 'hidden',
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.imageTracker}>
        {images.map((_, idx) => (
          <span
            key={idx}
            className={`${styles.imageDot} ${index === idx ? styles.imageDotActive : ''}`}
            onClick={() => setIndex(idx)}
          ></span>
        ))}
      </div>
    </section>
  );
};

export default Line;
